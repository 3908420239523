<template>
	<v-autocomplete
		:id="id"
		:label="label"
		:loading="loading"
		:disabled="disabled"
		:clearable="clearable"
		:items="items"
		:rules="rules"
		:prepend-inner-icon="prependInnerIcon"
		:append-icon="appendIcon"
		:append-outer-icon="appendOuterIcon"
		:item-text="itemText"
		:item-value="itemValue"
		:hide-details="hideDetails"
		:return-object="returnObject"
		:multiple="multiple"
		:autofocus="autofocus"
		:readonly="readonly"
		:reverse="reverse"
		outlined
		class="pt-0"
		:class="{
			...contentClass,
			'mt-3': !hideTopMargin,
			'dense-autocomplete': dense,
			'time-picker-autocomplete': timePicker,
		}"
		:menu-props="menuProps"
		v-model="autocompleteinput"
		:placeholder="getPlaceholder()"
		v-on:click:clear="$emit('click:clear', true)"
		v-on:change="$emit('change', autocompleteinput)"
		v-on:blur="$emit('blur', autocompleteinput)"
		v-on:click:append-outer="$emit('click:append-outer', true)"
	>
		<template v-slot:no-data>
			<v-list-item>
				<v-list-item-title v-html="`No ${placeholder} Found.`"></v-list-item-title>
			</v-list-item>
		</template>
		<template v-slot:item="data">
			<template>
				<div class="d-flex w-100 py-1">
					<v-list-item-content :class="data.item.sub_budget_id ? 'ml-5' : ''">
						<v-list-item-title v-html="data.item.text"></v-list-item-title>
					</v-list-item-content>
					<v-list-item-action>
						<v-chip label color="blue white--text">${{ data.item.cost ? data.item.cost : 0 }}</v-chip>
					</v-list-item-action>
				</div>
			</template>
		</template>
	</v-autocomplete>
</template>
<script>
export default {
	name: "autocomplete-input",
	model: {
		prop: "value",
		event: "change",
	},
	props: {
		value: {
			type: [Object, Number, String, Array],
			default: null,
		},
		hideTopMargin: {
			type: Boolean,
			default: false,
		},
		items: {
			type: Array,
			required: true,
			default: () => {
				return [];
			},
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		contentClass: {
			type: Object,
			default: null,
		},
		prependInnerIcon: {
			type: String,
			default: null,
		},
		appendIcon: {
			type: String,
			default: "mdi-menu-down",
		},
		appendOuterIcon: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		itemText: {
			type: String,
			default: "text",
		},
		itemValue: {
			type: String,
			default: "value",
		},
		dense: {
			type: Boolean,
			default: false,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		autofocus: {
			type: Boolean,
			default: false,
		},
		timePicker: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		reverse: {
			type: Boolean,
			default: false,
		},
		returnObject: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler(value) {
				this.autocompleteinput = value;
			},
		},
	},
	data() {
		return {
			autocompleteinput: null,
			menuProps: {},
		};
	},
	methods: {
		getPlaceholder() {
			return `Select ${this.placeholder}`;
		},
	},
	mounted() {
		this.autocompleteinput = this.value;
		if (this.dense) {
			this.menuProps.contentClass = "dense-autocomplete-list";
		}
		if (this.timePicker) {
			this.menuProps.contentClass = "time-picker-autocomplete-list";
		}
	},
};
</script>
